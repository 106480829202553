<template>
    <iframe id="iframe" frameborder="0" class="live__view__frame"></iframe>
</template>

<script>
import { onAccountAvailable, refContract } from "@stakeordie/griptape.js";
import { fromBase64, initContracts } from "@/utils";
import axios from 'axios';
import api from '@/service/api';

export default {
    data() {
        return {
            project: [],
        }
    },
    async mounted() {
        await this.getProject(this.$route.params.projectId);
        await initContracts();
        onAccountAvailable(() => { this.initialize(); });
    },
    methods: {
        async getProject(id) {
            this.project = await api.projects.get(id);
        },
        async initialize() {
            const searchParams = new URLSearchParams(window.location.search);
            const artId = this.$route.params.artId  ; 
            const size = searchParams.get('size');

            const iframe = document.getElementById('iframe');
            //TODO: find a nicer way of render live view for each project
            if (this.project.projectId === '1') {
                iframe.srcdoc = await this.getDoc(size);
            } else {
                if(this.project.title.toLowerCase().includes("resilient")){
                    iframe.srcdoc = await this.getCodeResilient(artId);
                }else{
                    iframe.srcdoc = await this.getDocWithTraits();
                }
            }

            if (size) {
                await this.downloadImage(iframe);
            }
        },
        async getCodeResilient(artId){
            // Get token data
            const contract = refContract(this.project.snip721ContractAddress);
            const { nft_dossier } = await contract.getNftDossier(artId);
            const { seed, private_metadata } = nft_dossier;
            const answers = {};

            for (const item of private_metadata.extension.attributes) {
                answers[item.trait_type] = item.value;
            }

            const template = `<script>function getTokenInfo() { return { seed: "${seed}", answers: { background: "${answers.background}" } } }<\/script>`;
            const textToReplace = "<!-- FOR MINTING ONLY, DO NOT MODIFY -->";
            const { code: { code } } = await contract.getP5jsCode();
            if(code.startsWith('https://')) {
                const htmlResponse = await axios.get(code);
                return htmlResponse.data.replace(textToReplace, template); 
            }   
        },

        downloadImage(iframe) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    const { artId } = this.$route.params;
                    iframe.contentWindow.save(`${artId}.png`);
                    resolve();
                }, 5000);
            });
        },

        async getDoc(size) {
            const snip721Contract = refContract(this.project.snip721ContractAddress);
            const { artId } = this.$route.params;
            const { nft_dossier } = await snip721Contract.getNftDossier(artId);
            const { private_metadata, seed } = nft_dossier;
            if (!private_metadata) {
                window.close();
            }
            const answers = {};
            for (const item of private_metadata.extension.attributes) {
                answers[item.trait_type] = item.value;
            }
            const sizeScript = size ? `window.location.search = '?size=${size}';` : "";
            const textToReplace = "<!-- FOR MINTING ONLY, DO NOT MODIFY -->";
            
            const getTokenInfoScript = `<script>${sizeScript} function getTokenInfo(projectNumber) { return { answers: { "Color Palette": '${answers['Color Palette']}', "Number of Rings": ${parseFloat(answers['Number of Rings'])}, "Genesis Ring Points": ${parseFloat(answers['Genesis Ring Points'])} }, hash: '${seed}', tokenId: ${artId}}}<\/script>`;
            const { code: { code } } = await snip721Contract.getP5jsCode();
            return fromBase64(code).replace(textToReplace, getTokenInfoScript);
        },

        async getDocWithTraits() {
            const snip721Contract = refContract(this.project.snip721ContractAddress);
            const { artId } = this.$route.params;
            const { nft_dossier } = await snip721Contract.getNftDossier(artId);
            const { public_metadata, seed } = nft_dossier;
            const attributes = public_metadata.extension.attributes;
            const $attributes = {};
            if (!public_metadata) { window.close(); }

            for (const attr of attributes) {
                $attributes[attr.trait_type] = attr.value;         
            }
            
            const $answers = Object.keys($attributes)
            .map(key => {
                let value = $attributes[key]; 
                if (!isNaN(value)) {
                    value = parseInt(value);
                } else {
                    value = `"${value}"`; 
                }  
                return `"${key}":${value}`;
            })
            .join(",");
            
            const $features = $answers;

            const textToReplace = "<!-- FOR MINTING ONLY, DO NOT MODIFY -->";

            const template = `<script> function getTokenInfo() { return { answers: {${$answers}},features: {${$features}},hash: "${seed}",tokenId: "${artId}"}}<\/script>`;

            const { code: { code } } = await snip721Contract.getP5jsCode();
            return fromBase64(code).replace(textToReplace, template);
        },
    }
}
</script>

<style lang="scss">
iframe {
    &.live__view__frame {
        background-color: black;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
}
.mint__closing__alert__container {
    display: none;
}
</style>
